<template>
  <PastServices>
    <PastServiceCard
      :loading="loading"
      icon="car"
      theme="success"
      subText="Next service: 300,000"
      text="234,677 Miles"
      :title="$t('pastServices.title')"
    />
    <PastServiceCard
      :loading="loading"
      icon="close-circle"
      theme="danger"
      :subText="$t('global.time.tMonth')"
      :text="$tc('vehicle.incident', 2, [2])"
      :title="$tc('vehicle.incident', [2])"
    />
    <PastServiceCard
      :loading="loading"
      icon="star"
      theme="warning"
      :subText="$t('trips.served')"
      text="1256"
      :title="$t('trips.title')"
    />
    <PastServiceCard
      :loading="loading"
      :modalOptions="{ title: 'Modal title' }"
      icon="package-down"
      text="15"
      title="Changes History"
    >
      <template #modal-body> MODAL CONTENT </template>
    </PastServiceCard>
  </PastServices>
</template>

<script>
import { PastServices, PastServiceCard } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);
  },
  computed: {
    hasPermission() {
      return this.Secure.permissionValidator(Permissions.ActivityLogger.read);
    }
  },
  data() {
    return {
      loading: true
    };
  },
  components: {
    PastServices,
    PastServiceCard
  }
};
</script>

<style lang="sass" scoped>
.pastServices
  margin: 0!important
  padding: 0!important
  ::v-deep
    .card-content
      padding: 15px 0 0!important
      margin: 0 -5px!important
</style>
