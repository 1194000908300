<template>
  <Card :class="vehicle.id ? '' : 'is-disabled'">
    <template #header>
      <div class="flex f-jsb f-ai">
        <p>
          {{ vehicle.name || $tc('vehicle.label', 1) }}
        </p>
        <div class="flex f-ai">
          <Link class="tablet" label="Tablet" @click="() => showMap('showTablet', vehicle)">
            Tablet
            <b-icon icon="map-marker" />
          </Link>
        </div>
      </div>
    </template>
    <div>
      <div class="profile flex">
        <MainCardPicture
          :id="vehicle.id"
          :src="vehicle.pictureSrc"
          api="vehicle"
          :alt="vehicle.name"
        />
        <div class="details">
          <h2>{{ $t('vehicle.infoLabel') }}</h2>
          <div class="flex">
            <Card-item :title="$t('vehicle.name')" :value="vehicle.name" expanded />
            <Card-item
              :title="$t('vehicle.group')"
              :value="vehicle['vehicle_group.name']"
              expanded
            />
          </div>
          <div class="flex f-jsb">
            <Card-item :title="$t('vehicle.phoneLogin')" expanded>
              <QRDownloader
                :text="vehicle.smartphone_login"
                :name="`QR_login_${vehicle.name}`"
                :disabled="!vehicle.smartphone_login"
                isLink
              />
            </Card-item>
            <Card-item :title="$t('vehicle.vin')" :value="vehicle.vin" expanded />
          </div>
          <div class="flex f-jsb">
            <Card-item :title="$t('global.make')" :value="vehicle.make" />
            <Card-item :title="$t('global.model')" :value="vehicle.model || '-'" />
            <Card-item :title="$tc('global.year')" :value="vehicle.year || '-'" />
            <Card-item :title="$t('global.color')" :value="getColor(vehicle.color) || '-'" />
          </div>
          <div class="flex f-jsb">
            <Card-item :title="$t('vehicle.garage')">
              <AddressViewer :address="address" :mapBreadcumbs="['menu.veh', vehicle.full_name]" />
            </Card-item>
          </div>
          <div class="flex f-jsb">
            <Card-item :title="$t('global.imei')" :value="vehicle.imei || '-'" expanded />
            <Card-item title="MTS" :value="vehicle.mts_number || '-'" expanded />
          </div>
        </div>
      </div>
    </div>
    <PastServices />
    <template #footer>
      <div class="flex f-full f-ai f-jsb">
        <My-switch
          :value="vehicle.is_active"
          :text="$tc('switch.active', vehicle.is_active ? 1 : 2)"
          @input="toggleActive"
          :disabled="!vehicle.id || loading.toggleActive"
          :loading="loading.toggleActive"
          :permission="Permission.setDefault"
        />
        <div class="buttons">
          <Button
            type="is-primary"
            @click="onEdit"
            :label="$t('button.edit')"
            :disabled="!vehicle.id"
            :permission="Permission.update"
          />
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import AddressViewer from '@/components/Address/AddressViewer.vue';
import Card from '@/components/Card.vue';
import CardItem from '@/components/CardItem.vue';
import Link from '@/components/Link';
import MainCardPicture from '@/components/MainCardPicture.vue';
import MySwitch from '@/components/iSwitch.vue';
import PastServices from './PastServices.vue';
import QRDownloader from '@/components/QRDownloader.vue';
import { Button } from '@/components';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    AddressViewer,
    Button,
    Card,
    CardItem,
    Link,
    MainCardPicture,
    MySwitch,
    PastServices,
    QRDownloader
  },
  data() {
    return {
      loading: { toggleActive: false },
      isDisabled: !this.vehicle.is_active
    };
  },
  computed: {
    address() {
      const { full_address, latitude: lat, longitude: lng } = this.vehicle;
      return { full_address, latLng: { lat, lng } };
    },
    Permission() {
      return Permissions.Vehicles;
    },
    colors() {
      return [
        { id: 'BLACK', label: this.$t('vehicle.colors.black') },
        { id: 'BLUE', label: this.$t('vehicle.colors.blue') },
        { id: 'GRAY', label: this.$t('vehicle.colors.gray') },
        { id: 'GREEN', label: this.$t('vehicle.colors.green') },
        { id: 'RED', label: this.$t('vehicle.colors.red') },
        { id: 'WHITE', label: this.$t('vehicle.colors.white') },
        { id: 'YELLOW', label: this.$t('vehicle.colors.yellow') }
      ];
    }
  },
  watch: {
    vehicle() {
      this.isDisabled = !this.vehicle.is_active;
    }
  },
  methods: {
    getColor(id) {
      return this.colors.find((color) => color.id === id)?.label;
    },
    onEdit() {
      this.$router.push({ path: `/data/vehicles/${this.vehicle.id}` });
    },
    showMap(action, data) {
      this.$store.dispatch('map/updateMapWindow', { action, data });
    },
    async toggleActive(is_active) {
      this.loading.toggleActive = true;
      try {
        await this.Api.patch(`vehicles/${this.vehicle.id}`, { is_active });
        this.vehicle.is_active = is_active;
        this.isDisabled = !is_active;
        this.$toast('success', this.$t('messages.updated'), 3000);
      } catch (error) {
        const value = this.vehicle.is_active;
        this.vehicle.is_active = !value;
        setTimeout(() => {
          this.vehicle.is_active = value;
        }, 100);
      }
      this.loading.toggleActive = false;
    }
  },
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: 100%
  width: 100%
  margin: 0
  ::v-deep .card-content
    padding-bottom: 5px
    display: flex
    flex-flow: column
    justify-content: space-between
.nav-icon
  position: absolute
  top: 2px
  left: -5px
  font-size: 14px
  transform: rotate(40deg)
.tablet
  padding: 0 10px
  font-weight: 18px
  font-weight: bold
  &:hover
    .icon
      color: darken($primary,5)
  .icon
    color: $primary
.profile
  .details
    width: 100%
    padding: 0
    h2
      font-size: 18px
      font-weight: bold
      margin-bottom: 15px
    .flex
      margin-bottom: 30px
@media screen and ( max-width: $bp-xxl )
  .profile
    .flex
      margin-bottom: 10px!important
.buttons
  justify-content: space-between
.iSwitch
  margin: 0
</style>

<style lang="sass">
.dark #vehicle-card .tablet
  &:hover
    .icon
      color: $primary-dark-hover
  .icon
    color: $primary-dark
</style>
