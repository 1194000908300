<template>
  <Card class="p-card-il" :class="active ? 'active' : ''" @click="onCardClick">
    <div class="picture-container" :class="disabled">
      <div class="picture">
        <Picture
          :src.sync="vehicle.pictureSrc"
          :preasigned="{
            apiUrl: '/presigned/vehicle',
            fileName: `${vehicle.id}.png`
          }"
          :alt="vehicle.name"
        />
      </div>
    </div>
    <div class="details" :class="disabled">
      <div class="flex header">
        <strong>{{ vehicle.name }}</strong>
        <strong>{{ vehicle.license_plate_number }}</strong>
      </div>
      <p>
        {{ vehicle['vehicle_group.name'] }}
      </p>
      <div class="tag" v-if="vehicle['vehicle_capacity_type.name']">
        {{ vehicle['vehicle_capacity_type.name'] }}
      </div>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue';
import Picture from '@/components/Picture.vue';

export default {
  components: {
    Card,
    Picture
  },
  computed: {
    disabled() {
      return this.vehicle.is_active ? '' : 'is-disabled';
    }
  },
  methods: {
    onCardClick() {
      this.$emit('click', this.vehicle);
    }
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="sass" scoped>
$pictureSize: 72px
.card
.p-card-il
  ::v-deep
    .card-content
      padding: 0
      display: flex
.picture-container
  justify-content: center
  min-width: $pictureSize
  width: $pictureSize
  display: flex
  flex-flow: column
  margin-right: 25px
  .rate
    font-size: 12px
    margin: 0 auto
  .picture
    height: $pictureSize
    border-radius: $br-md
    overflow: hidden
    .b-image-wrapper
      margin: 0
.details
  width: 100%
  .header
    justify-content: space-between
    strong
      font-size: $f-lg
  p
    color: $gray-700
    margin-bottom: 5px
.tag
  color: $light-blue-800
  background: $light-blue-100
  text-transform: uppercase
  font-weight: 700
  border-radius: $br-md
</style>

<style lang="sass">
.dark
  .tag
    color: $light-blue-100
    background: $light-blue-900
</style>
