<template>
  <section>
    <Portal to="headerActions">
      <Link to="/data/vehicles/add" isRouter :permission="Perm.create">
        <b-button
          type="is-primary"
          :label="$t('button.add', [$tc('vehicle.label').toLowerCase()])"
          icon-left="plus"
        />
      </Link>
    </Portal>
    <b-loading :active="loading"></b-loading>
    <div class="vehicle flex">
      <Base-searcher
        class="mr-4"
        api="vehicles"
        @updated="onSearcherUpdate"
        queryKey="name"
        :placeholder="$t('vehicle.search.ph')"
        :useDropdown="false"
      >
        <template #default="{ data }">
          <Searcher-card
            v-for="item in data"
            :key="item.id"
            :active="vehicle == item"
            :vehicle="item"
            @click="onSelect"
          />
        </template>
      </Base-searcher>
      <div class="rigth is-full-touch">
        <Card id="vehicle-card" :vehicle="vehicle" />
      </div>
    </div>
  </section>
</template>
<script>
import SearcherCard from '@/components/Vehicles/SearcherCard.vue';
import Card from '@/components/Vehicles/Card';
import BaseSearcher from '@/components/BaseSearcher/Searcher.vue';
import { Link } from '@/components';
import { Permissions } from '@/utils/Secure';
export default {
  components: {
    SearcherCard,
    Card,
    Link,
    BaseSearcher
  },
  data() {
    return {
      vehicle: {},
      loading: false
    };
  },
  computed: {
    Perm() {
      return Permissions.Vehicles;
    }
  },
  methods: {
    onSearcherUpdate({ page, items }) {
      if (page == 1) this.vehicle = items[0] || {};
    },
    onSelect(vehicle) {
      this.vehicle = vehicle;
    }
  }
};
</script>
<style lang="sass" scoped>
.vehicle
  height: calc(100% - 95px)
  width: 100%
  ::v-deep .infinite-scroll
    height: calc(100% - 80px)
  .searcher,
  .rigth
    @include static-width(49.5%)
  .rigth
    padding-bottom: 5px
  @media screen and ( min-width: $bp-xxl )
    .searcher
      @include static-width(39.5%)
    .rigth
      @include static-width(59.5%)
</style>
